import React, { useEffect, useState, useRef } from 'react';
import './ChessSeparatorStyles.css';

const ChessSeparator = () => {
    const separatorRef = useRef<HTMLDivElement>(null);
    const [tileCount, setTileCount] = useState(0);
  
    useEffect(() => {
      const calculateTileCount = () => {
        if (separatorRef.current) {
          const parentWidth = separatorRef.current.scrollWidth;
          const tileWidth = 8; // Each tile is 8px wide
          setTileCount(Math.floor(parentWidth / tileWidth));
        }
      };
  
      calculateTileCount();
      window.addEventListener('resize', calculateTileCount);
  
      return () => window.removeEventListener('resize', calculateTileCount);
    }, []);
  
    const rows = [];
    for (let row = 0; row < 1; row++) {
      const tiles = [];
      for (let i = 0; i < tileCount; i++) {
        tiles.push(
          <div
            key={i}
            className="tile"
            style={{
              backgroundColor:
                (row % 2 === 0 ? i % 2 : (i + 1) % 2) === 0 ? '#462942' : '#d89a7a',
            }}
          ></div>
        );
      }
      rows.push(
        <div key={row} className="tileRow">
          {tiles}
        </div>
      );
    }
  
    return (
      <div className="chess-separator" ref={separatorRef}>
        <div className="top-border"></div>
        <div className="rows-container">{rows}</div>
        <div className="bottom-border"></div>
      </div>
    );
  };
  

export default ChessSeparator;