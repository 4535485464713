
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

import React from 'react';
import { useReactToPrint } from 'react-to-print';
import Loader from '../Loader/Loader';
import { CCSLogEvent, useAnalytics } from '../..';

import './DialogStyles.css';

export type DialogProps = {
    title: string;
    ribbonText?: string;
    isDialogModalOpen: boolean;
    showDownloadButton: boolean;
    close?: (result: boolean) => void;
    children: React.ReactNode;
};

// utils.js
export const convertImgToInlineSVG = async (imgElement: any) => {
    const response = await fetch(imgElement.src);
    const svgText = await response.text();
    const parser = new DOMParser();
    const svgDoc = parser.parseFromString(svgText, 'image/svg+xml');
    const svgElement: any = svgDoc.querySelector('svg');
    svgElement.setAttribute('width', imgElement.width);
    svgElement.setAttribute('height', imgElement.height);
    svgElement.setAttribute('style', imgElement.getAttribute('style'));
    svgElement.setAttribute('class', imgElement.getAttribute('class'));
    return svgElement;
  };

function Dialog({close, ribbonText, isDialogModalOpen, title, showDownloadButton, children }: DialogProps) {
    const [isGeneratingPDF, setIsGeneratingPDF] = React.useState(false);
    const [errorMessage, setErrorMessage] = React.useState('');

    const { track } = useAnalytics();

    const resetErrorLoading = React.useCallback(() => {
        setIsGeneratingPDF(false);
        setErrorMessage('');
    }, [setIsGeneratingPDF, setErrorMessage]);

    const onError = React.useCallback((error: any) => {
        track(CCSLogEvent.PDF_GENERATION_ERROR, {
            errorMessage: error?.message,
            stack: error?.stack,
        });
        console.error(error);
        setIsGeneratingPDF(false);
        setErrorMessage('Unable to get PDF');
    }, [setIsGeneratingPDF, setErrorMessage, track]);  

    const print = React.useCallback(async () => {
        setErrorMessage('');
        setIsGeneratingPDF(true);

        track(CCSLogEvent.PDF_GENERATION_STARTED);
        try {
            const element: any = document.getElementById('pdf-content');
            const totalHeight = element.scrollHeight;

            // Convert all img tags with SVG sources to inline SVG elements
            const imgElements = element.querySelectorAll('img');
            for (const img of imgElements) {
                if (img.src.endsWith('.svg')) {
                    const svgElement = await convertImgToInlineSVG(img);
                    img.replaceWith(svgElement);
                }
            }

            const scale = 2; // Increase the scale for better quality 

            // Create a canvas that covers the entire content height
            const canvas = await html2canvas(element, {
                useCORS: true,
                scale, // Increase the scale for better quality
            });

            const imgData = canvas.toDataURL('image/png');
            
            
            
            const pdfWidth = canvas.width / scale; // 595.28;
            // const pdfHeight = 841.89;
            // console.log('pdfWidth', pdfWidth, 'pdfHeight', pdfHeight);
            // const aspectRatio = canvas.width / canvas.height;
            // const pageHeight = pdfWidth / aspectRatio;

            // // Determine the total number of pages
            // const totalPages = Math.ceil(totalHeight / (pdfHeight / scale));

            const pdf = new jsPDF('p', 'pt', [pdfWidth, totalHeight * 2]);
            pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, canvas.height / scale);

            // // pdf.canvas.height = pageHeight * totalPages;
            // // pdf.canvas.width = pdfWidth;
            // // new jsPDF('p', 'mm', [pdfWidth, totalHeight]);

            // for (let page = 0; page < totalPages; page++) {
            //     if (page === 1) {
            //         pdf.addPage();
                    
            //     }

            //     const pageOffset = page * pdfHeight;
            //     pdf.addImage(
            //         imgData,
            //         'PNG',
            //         0,
            //         -pageOffset,
            //         pdfWidth,
            //         pdfWidth / aspectRatio
            //     );
            // }

            track(CCSLogEvent.PDF_GENERATION_SUCCESS);
            
            pdf.save('chesscomscout-report.pdf');

            resetErrorLoading();
        } catch (error) {
            onError(error);
        }
    }, [setIsGeneratingPDF, onError, resetErrorLoading, track]);

    const handleClose = React.useCallback(() => {
        resetErrorLoading();
        close && close(false);
    }, [close, resetErrorLoading]);

    const handleDownloadPDF = useReactToPrint({
        content: () => document.getElementById('pdf-content'),
        print,
        onAfterPrint: () => console.log('Print success!'),
        onPrintError: (error) => console.error('Print error:', error),
    });

    return (<>
    
        {isDialogModalOpen && (
            <div className="dialog-container">
                <div className='dialog-overlay'></div>
                <div className="dialog-wrapper">
                    <div className='ribbon'>{ribbonText ?? ''}</div>
                        <header className={`dialogTopBar ${title ? 'boxShadowBottom' : ''}`}>
                            {/* {title ?  */}
                            <div className='dialogTopBarLeftItem'>
                                {showDownloadButton && <>
                                    {isGeneratingPDF && <Loader color='black' />}
                                    {errorMessage && <p className='error'>{errorMessage}</p>}
                                    {(!isGeneratingPDF && !errorMessage) && <button onClick={handleDownloadPDF}>
                                        <i className="fa fa-download"></i>
                                    </button>}
                                </>}
                            </div>
                            <div className='dialogTopBarCenterItem'>
                                <h3>{title}</h3>
                            </div>
                            {close && <div className="closeButton" onClick={handleClose}>x</div>}
                        </header>
                        <div className='dialog' data-open={isDialogModalOpen}>
                            {children}
                        </div>
                </div>
            </div>
        )}
    </>);
}

export default Dialog;