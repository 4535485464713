import './Skeleton.css';
import './App.css';
import './Normalize.css';

import React from 'react';
import logo from './logo.svg';
import Dialog from './components/Dialog/Dialog';

import ChessSeparator from './components/ChessSeparator/ChessSeparator';

import PayPal from './components/PayPal/PayPal';
import { CCSLogEvent, useAnalytics } from '.';

import DemoVideo from './components/DemoVideo';
import AuthDialog from './components/AuthDialog/AuthDialog';
import { doubleCheckLink } from './utils/authUtils';
import { useAuth } from './context/AuthContext';
import LoggedInHeader from './components/LoggedInHeader/LoggedInHeader';

function App() {
  const [isMounted, setIsMounted] = React.useState(false);

  const [isLoginModalOpen, setIsLoginModalOpen] = React.useState(false);
  const [isPaymentDialogOpen, setIsPaymentDialogOpen] = React.useState(false);

  const handleTransactionSuccess = React.useCallback(() => {
    setIsPaymentDialogOpen(false);
  }, [setIsPaymentDialogOpen]);

  const handleClosePaymentDialog = React.useCallback(() => {
    setIsPaymentDialogOpen(false);
  }, [setIsPaymentDialogOpen]);

  const {
    track
  } = useAnalytics();

  const {
    isLoggedIn,
    currentUser,
    signOut,
  } = useAuth();

  React.useEffect(() => {
    doubleCheckLink().then((result) => {
      console.log('doubleCheckLink result', result);
      if (result) {
        const updatedUrl = window.location.protocol + "//" + window.location.host + window.location.pathname;
        window.history.pushState({path:updatedUrl},'',updatedUrl);
      }
    }).catch((error) => {
      console.error('doubleCheckLink error', error);
    });
  }, []);

  const checkPayment = React.useCallback(() => { 
    return currentUser?.getIdTokenResult(true).then((idTokenResult) => {
      if (idTokenResult.claims.unlocked) {
        // user has paid
        console.log('user has paid');
        return true;
      } else {
        console.log('user has not paid');
        return false;
      }
    }).catch((error) => {
      console.error('error getting id token result', error);
      return false;
    });
  }, [currentUser]);

  const showPaymentDialog = React.useCallback(() => {
    track(CCSLogEvent.VIEW_ITEM, {
      item_id: 'unlock',
      item_name: 'Unlock',
      currency: 'USD',
      value: 19.99,
    });
    setIsPaymentDialogOpen(true);
  }, [setIsPaymentDialogOpen, track]);

  React.useEffect(() => {
    if (!isLoggedIn) {
      return;
    }
    checkPayment()?.then((result) => {
      if (!result) {
        showPaymentDialog();
      }
    })
  }, [isLoggedIn, showPaymentDialog, checkPayment]);

  React.useEffect(() => {
    if (!isMounted) {
      setIsMounted(true);
      track(CCSLogEvent.PAGE_VIEWED, { page: 'home' });
      return;
    }
  }, [isMounted, track]);

  const handleCloseLoginDialog = React.useCallback(() => {
    setIsLoginModalOpen(false);
  }, [setIsLoginModalOpen]);


  const feedbackButtonClicked = React.useCallback(() => {
    track(CCSLogEvent.FEEDBACK_CLICKED);
    window.open('mailto:chesscomscout@gmail.com');
  }, [track]);
  
  const getScoutingClicked = React.useCallback(() => {
    track(CCSLogEvent.GET_SCOUTING_CLICKED);
    setIsLoginModalOpen(true);
  }, [setIsLoginModalOpen, track]);

  return (
    <div className="App">
      <div className="topBar">
        {!isLoggedIn && <>
          <div className='anchorContainer'>
            <a href='/#'>Home</a>
            <a href='/#demo'>Demo</a>
            <a href='/#pricing'>Pricing</a>
            <a href='/#whyitworks'>Why it works?</a>
            <a href='/#feedback'>Feedback</a>
          </div>
          <input type='button' className="button-primary" onClick={getScoutingClicked} value='Log in' />
        </>}
        {isLoggedIn && <>
          {currentUser?.photoURL && <img className='icon' src={currentUser.photoURL} alt={currentUser.displayName ?? 'user profile image'} referrerPolicy="no-referrer" />}
          {!(currentUser?.photoURL) && <i className="fa fa-user"></i>}

          <p>{currentUser?.displayName ?? currentUser?.email}</p>
          <input type='button' className="button-primary" onClick={signOut} value='Log out' />
        </>}
      </div>
      {isLoggedIn && <LoggedInHeader checkPayment={checkPayment} showPaymentDialog={showPaymentDialog} />}
      {!isLoggedIn && <>
        <header className="container App-header">
          <div className="row">
            <img src={logo} className="App-logo" alt="logo" />
            <h1 className='frontpageTitle'>AI Powered Chess Preparation</h1>
          </div>
          <div className="row">
            <input type='button' className="button-primary" onClick={getScoutingClicked} value='Start scouting' />
          </div>
      </header>
      <div className="container">
          <div className="row badgeColumnContainer">
            <div className="four columns badgeIconAndText">
              <img className='badgeIcon' src={'/victory-stand-256.png'} alt="victory stand" />
              <p>Get an edge over your opponents by generating detailed reports on their weaknesses.</p>      
            </div>
            <div className="four columns badgeIconAndText">
              <img className='badgeIcon' src={'/doctor-256.png'} alt="doctor" />
              <p>Investigate your own account. Diagnose your weaknesses and quickly improve your chess win rate.</p>
            </div>
            <div className="four columns badgeIconAndText">
              <img className='badgeIcon' src={'/clock-256.png'} alt="clock" />
              <p>All in a matter of seconds. Just in time for your upcoming Blitz match!</p>
            </div>
          </div>
      </div>
      <section id='demo' className='container no-bottom-margin'>
        <h3>See it for yourself</h3>
        <div className='row'>
          <DemoVideo />
        </div>
      </section>
      <ChessSeparator />
      <div className="rounded container reviewContainer no-bottom-margin">
        <div className="row starContainer">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="star"><path fill-rule="evenodd" d="M10.868 2.884c-.321-.772-1.415-.772-1.736 0l-1.83 4.401-4.753.381c-.833.067-1.171 1.107-.536 1.651l3.62 3.102-1.106 4.637c-.194.813.691 1.456 1.405 1.02L10 15.591l4.069 2.485c.713.436 1.598-.207 1.404-1.02l-1.106-4.637 3.62-3.102c.635-.544.297-1.584-.536-1.65l-4.752-.382-1.831-4.401z" clip-rule="evenodd"></path></svg>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="star"><path fill-rule="evenodd" d="M10.868 2.884c-.321-.772-1.415-.772-1.736 0l-1.83 4.401-4.753.381c-.833.067-1.171 1.107-.536 1.651l3.62 3.102-1.106 4.637c-.194.813.691 1.456 1.405 1.02L10 15.591l4.069 2.485c.713.436 1.598-.207 1.404-1.02l-1.106-4.637 3.62-3.102c.635-.544.297-1.584-.536-1.65l-4.752-.382-1.831-4.401z" clip-rule="evenodd"></path></svg>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="star"><path fill-rule="evenodd" d="M10.868 2.884c-.321-.772-1.415-.772-1.736 0l-1.83 4.401-4.753.381c-.833.067-1.171 1.107-.536 1.651l3.62 3.102-1.106 4.637c-.194.813.691 1.456 1.405 1.02L10 15.591l4.069 2.485c.713.436 1.598-.207 1.404-1.02l-1.106-4.637 3.62-3.102c.635-.544.297-1.584-.536-1.65l-4.752-.382-1.831-4.401z" clip-rule="evenodd"></path></svg>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="star"><path fill-rule="evenodd" d="M10.868 2.884c-.321-.772-1.415-.772-1.736 0l-1.83 4.401-4.753.381c-.833.067-1.171 1.107-.536 1.651l3.62 3.102-1.106 4.637c-.194.813.691 1.456 1.405 1.02L10 15.591l4.069 2.485c.713.436 1.598-.207 1.404-1.02l-1.106-4.637 3.62-3.102c.635-.544.297-1.584-.536-1.65l-4.752-.382-1.831-4.401z" clip-rule="evenodd"></path></svg>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="star"><path fill-rule="evenodd" d="M10.868 2.884c-.321-.772-1.415-.772-1.736 0l-1.83 4.401-4.753.381c-.833.067-1.171 1.107-.536 1.651l3.62 3.102-1.106 4.637c-.194.813.691 1.456 1.405 1.02L10 15.591l4.069 2.485c.713.436 1.598-.207 1.404-1.02l-1.106-4.637 3.62-3.102c.635-.544.297-1.584-.536-1.65l-4.752-.382-1.831-4.401z" clip-rule="evenodd"></path></svg>
        </div>
        <div className='row'>
          <p>I've been playing chess for three years now.</p>
          <p>For many months I was plateauing around 1700 Rapid on chess.com.</p>
          <p>Playing game after game without any visible improvement.</p>
          <p>After I figured out effective metrics to look into when scouting players I was able to use my Computer Science knowledge to generate scout reports before my matches</p>
          <p><span className='highlightedText'>Within 3 months I had made it to 1950 rapid</span></p>
          <p>I built ChessComScout so that anyone could download the type of reports that so rapidly improved my chess.</p>
        </div>
        <div
          className='row reviewer'
        >
          <img className='icon' src="https://images.chesscomfiles.com/uploads/v1/user/107782742.3849c654.200x200o.96bd7774cfa2.jpeg" alt="thorvaldurru" />
          <p>thorvaldurru</p>
        </div>
      </div>
      <ChessSeparator />
      <div className='container no-bottom-margin'>
        <div className='row'>
          <h5>"Even a poor plan is better than no plan at all..."</h5>
          <div
            className='row'
          >
            <p>Mikhail Chigorin</p>
          </div>
        </div>
      </div>

      <div className='container no-bottom-margin'>
        <div className='row'>
          <h5>...imagine how much better a data driven plan could be</h5>
        </div>
      </div>
      <ChessSeparator />
      <section id='pricing' className='container'>
        <h3>Pricing</h3>
        <div className='row'>
          <div className='pricingColumn'>
            <div className='ribbon'>Launch discount</div>
            <h6 className='strikethrough'>$29.99</h6>
            <h5>$19.99</h5>
            <p>Pay only once - Unlimited reports!</p>
            <p>Scout as many chess.com accounts as you want!</p>
            <input type='button' className="button-primary" onClick={getScoutingClicked} value='Get started' />
          </div>
        </div>
      </section>
      <ChessSeparator />
      <section id='whyitworks'>
        <div className='container improvement no-bottom-margin'>
          <h3>Why it works?</h3>
          <h5>Players who utilized scout reports gained as high as 43 ELO points more compared to those who didn't in only 50 games.</h5>
          <h5>Here are some reasons why:</h5>
          <br/>
          <div className="row badgeColumnContainer">
            <div className="badgeIconAndText">
              <div className='number'>1</div>
              <p><strong>Knowing opponents weaknesses: </strong>At sub-2000 Elo levels, players frequently exhibit straightforward weaknesses that can be detected by even basic AI models. These vulnerabilities also tend to be relatively easy to capitalize on during gameplay. Recognizing these weaknesses in advance can provide a significant advantage and boost chances of success.</p>
            </div>
            <div className="badgeIconAndText">
                <div className='number'>2</div>
                <p><strong>More focused: </strong>Players armed with scout reports exhibit heightened focus and resilience, making them less susceptible to tilt. We believe this can be attributed to the clear gameplan provided by the scout reports, enabling them to maintain concentration throughout the match.</p>
              </div>
              <div className="badgeIconAndText">
                <div className='number'>3</div>
                <p><strong>Motivated: </strong> Scout reports can prevent chess plateaus by boosting success rates, fostering long-term engagement, and facilitating continuous improvement.</p>
              </div>
          </div>
        </div>
      </section>
      <ChessSeparator />
      <section id='feedback' className='container'>
        <div className='row feedback'>
          <h3>Feedback</h3>
          <h5>
            We are constantly improving ChessComScout. If you have any feedback, give us a shout!
          </h5>
          <input type='button' className="button-primary" onClick={feedbackButtonClicked} value='Send feedback' />
        </div>
      </section>

      
      </>}
      <AuthDialog isDialogModalOpen={isLoginModalOpen} close={handleCloseLoginDialog}/>
      <Dialog showDownloadButton={false} isDialogModalOpen={isPaymentDialogOpen} title=' ' close={handleClosePaymentDialog}>
        <PayPal handleTransactionSuccess={handleTransactionSuccess} />
      </Dialog>
    </div>
  );
}

export default App;
