import React from 'react';
import './LoaderStyles.css';

type LoaderProps = {
    color?: 'white' | 'black'
    size?: 'large' | 'medium' | 'small';
}
function Loader(props: LoaderProps) {
  return (
    <div className={`loader center ${props.color ?? 'white'} ${props.size ?? 'medium'}`}>
      <i className="fa fa-solid fa-spinner fa-spin"></i>
    </div>
  );
}

export default Loader;