import './PayPalStyles.css';
import React, { startTransition } from 'react';
import Loader from '../Loader/Loader';
import { CCSLogEvent, useAnalytics } from '../..';
import { useAuth } from '../../context/AuthContext';

type PayPalProps = {
    country?: string;
    handleTransactionSuccess?: (gameReports: any[]) => void;
};

function PayPal({ handleTransactionSuccess }: PayPalProps) {

  const { currentUser } = useAuth();
  const [isLoading, setIsLoading] = React.useState<'loading' | 'callingAI' | 'none'>('none');

  const [mounted, setMounted] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState('');

  const { track } = useAnalytics();

  const onError = React.useCallback((error: Error) => {
    console.error(error);
    track(CCSLogEvent.PURCHASE_ERROR, {
      errorMessage: error.message,
      stack: error.stack,
    });
    setIsLoading('none');
    setErrorMessage('Sorry, your transaction could not be processed...');
  }, [setIsLoading, setErrorMessage, track]);
  
  const onTransactionSuccess = React.useCallback((transaction: any, gameReports: any[]) => {
    track(CCSLogEvent.PURCHASE_SUCCESS, {
      transaction,
      value: 19.99,
      currency: 'USD',
    });
    handleTransactionSuccess && handleTransactionSuccess(gameReports);
  }, [handleTransactionSuccess, track]);

  const paypalRef = React.useRef<HTMLDivElement>(null);

  const createOrder = React.useCallback(async () => {
    setIsLoading('loading');
    return currentUser?.getIdToken().then((token) => {
      return fetch("/api/orders", {
        method: "POST",
        headers: {
          "Authorization": `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        // use the "body" param to optionally pass additional order information
        // like product ids and quantities
        body: JSON.stringify({
          cart: [
            {
              id: "UNLOCK",
              quantity: 1,
            },
          ],
        }),
      }).then(response => {
        return response.json();
      }).then(orderData => {
        if (orderData.id) {
          setIsLoading('none');
          return orderData.id;
        }
        const errorDetail = orderData?.details?.[0];
        const errorMessage = errorDetail
          ? `${errorDetail.issue} ${errorDetail.description} (${orderData.debug_id})`
          : JSON.stringify(orderData);
        onError(new Error(errorMessage));
      })
      .catch(error => {
        console.error('orders error', error);
        onError(new Error('Could not initiate PayPal Checkout...'));
      });
    }).catch(error => {
      console.error('getIdToken error', error);
      onError(new Error('Could not initiate PayPal Checkout...'));
    });
    
  }, [setIsLoading, onError, currentUser]);

  const onApprove = React.useCallback((data: any, actions: any) => {
    setIsLoading('callingAI');
    // console.log('Approving transaction:', data.orderID);
    // console.log('Data to send:', selectedUserData);
    return currentUser?.getIdToken().then((token) => {

      return fetch(`/api/orders/${data.orderID}/capture`, {
        method: "POST",
        headers: {
          "Authorization": `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({}),
      }).then(response => {
        return response.json();
      }).then(orderData => {
        setIsLoading('none');
        
        // Three cases to handle:
        //   (1) Recoverable INSTRUMENT_DECLINED -> call actions.restart()
        //   (2) Other non-recoverable errors -> Show a failure message
        //   (3) Successful transaction -> Show confirmation or thank you message

        const errorDetail = orderData?.details?.[0];
        
        if (errorDetail?.issue === "INSTRUMENT_DECLINED") {
          // (1) Recoverable INSTRUMENT_DECLINED -> call actions.restart()
          // recoverable state, per
          // https://developer.paypal.com/docs/checkout/standard/customize/handle-funding-failures/
          return actions.restart();
        } else if (errorDetail) {
          // (2) Other non-recoverable errors -> Show a failure message
          console.error(`Approve ERROR 2 ${errorDetail.description} (${orderData.debug_id})`);
          onError(new Error(errorDetail.description));
        } else if (!orderData.purchase_units) {
          console.error('Approve ERROR no purchase units', JSON.stringify(orderData));
          onError(new Error('Sorry, your transaction could not be processed...'));
        } else {
          // (3) Sucscessful transaction -> Show confirmation or thank you message
          // Or go to another URL:  actions.redirect('thank_you.html');
          const transaction =
            orderData?.purchase_units?.[0]?.payments?.captures?.[0] ||
            orderData?.purchase_units?.[0]?.payments?.authorizations?.[0];
            onTransactionSuccess(transaction, orderData.gameReports);
          }
        }).catch(error => {
          console.error('onApprove error', error);
          onError(new Error('Sorry, your transaction could not be processed...'));
        });
      }).catch(error => {
        console.error('getIdToken error', error);
        onError(new Error('Sorry, your transaction could not be processed...'));
      });
  }, [setIsLoading, onError, onTransactionSuccess, currentUser]);

  const renderButtons = React.useCallback(() => {
    if ((window as any).paypal) {
        (window as any).paypal.Buttons({
          style: {
            shape: "rect",
            layout: "vertical",
            color: "white",
            label: "paypal",
          },
          createOrder,
          onApprove,
        }).render(paypalRef.current!);
      }
  }, [createOrder, onApprove, paypalRef]);

  const mount = React.useCallback(() => {
    startTransition(() => {
      const script = document.createElement('script');
      // console.log('Loading PayPal script...', country);
      script.id = 'paypal-sdk';
      script.src = `https://www.paypal.com/sdk/js?client-id=AdeFqP_iQRchpc2mpe519gAeKKwjsXiLyM2mIrXw1oXxgp-bauKmmhxMOM4O3tomUEaWXFAwp4p7SwpR&currency=USD&components=buttons&enable-funding=venmo`;
      // script.src = `https://www.paypal.com/sdk/js?client-id=AS-RrmAMY4-vdNVMo6GCerpIlUTtIp0D0MNWbp4N0VXLPxP83KLFjcYzee9pI5ub5SyI5I5Ou5nUMFBn&buyer-country=${country}&currency=USD&components=buttons&enable-funding=venmo`;
      script.addEventListener('load', renderButtons);
      document.body.appendChild(script);
      setMounted(true);
    })
  }, [setMounted, renderButtons]);

  React.useEffect(() => {
    
    if (!mounted) {  
      mount();
    }
    // const getNearestParentOfTypeDialog = (element: HTMLElement | null): HTMLDialogElement | null => {
    //   if (!element) {
    //     return null;
    //   }
    //   if (element.tagName === 'DIALOG') {
    //     return element as HTMLDialogElement;
    //   }
    //   return getNearestParentOfTypeDialog(element.parentElement);
    // }

    // getNearestParentOfTypeDialog(paypalRef.current)?.addEventListener('close', () => {
    //   console.log('Dialog closed');
    //   document.getElementById('paypal-sdk')?.remove();
    //   mount();
    // });
  }, [mounted, mount]);
  

  return <>
    {(isLoading !== 'none' || !mounted) ? <>
      <Loader color='black' size='large' />
      <p>Kramnik at work...</p>
    </> : <div>
        <h3 className='paypalCheckout'>Finish sign up</h3>
        <p className='paypalCheckout'>Unlock unlimited scout reports.</p>
        <p className='paypalCheckout strikethrough'>$29.99</p>
        <h4 className='paypalCheckout'>$19.99</h4>
    </div>}
    {(isLoading === 'none' || isLoading === 'loading') && <div className='row' ref={paypalRef}>
    {errorMessage && <div className='row'>
    <p className='error'>{errorMessage}</p>
    </div>}
    </div>}
  </>
}

export default PayPal;