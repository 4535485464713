import React from 'react';

function DemoVideo() {
    const videoRef = React.useRef<HTMLVideoElement>(null);

    React.useEffect(() => {
        const video = videoRef.current;
        if (!video) {
            return;
        }

        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    video.src = video.dataset.src ?? '';
                    observer.unobserve(video);
                }
            });
        }, {
            threshold: 0,
        });

        observer.observe(video);

        return () => {
            observer.unobserve(video);
        };
    }, []);

    return (
        <video
            ref={videoRef}
            className='demo-video'
            data-src="/demo.mp4"
            autoPlay
            loop
            controls
        />
    );
}

export default DemoVideo;