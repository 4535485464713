import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { AnalyticsCallOptions, getAnalytics, logEvent } from "firebase/analytics";
import AuthProvider from './context/AuthContext';

export enum CCSLogEvent {
  PAGE_VIEWED = 'page_viewed',
  
  FEEDBACK_CLICKED = 'feedback_clicked',
  GET_SCOUTING_CLICKED = 'get_scouting_clicked',

  VIEW_ITEM = 'view_item',
  PURCHASE_STARTED = 'purchase_started',
  PURCHASE_SUCCESS = 'purchase',
  PURCHASE_ERROR = 'purchase_error',

  PDF_GENERATION_STARTED = 'pdf_generation_started',
  PDF_GENERATION_SUCCESS = 'pdf_generation_success',
  PDF_GENERATION_ERROR = 'pdf_generation_error',
}

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDy6P0hkGVpGsNyrnzeeu7NLooZc5Mo9nQ",
  authDomain: "chess-com-scout.firebaseapp.com",
  projectId: "chess-com-scout",
  storageBucket: "chess-com-scout.appspot.com",
  messagingSenderId: "389258368569",
  appId: "1:389258368569:web:a4073001cbc4ffa1409fe8",
  measurementId: "G-Z5G8LM131D"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

const track = (event: string, payload?: {[key: string]: any}, callOptions?: AnalyticsCallOptions) => {
  logEvent(analytics, event, payload, callOptions);
};

const AnalyticsContext = React.createContext({
  track,
});

export const useAnalytics = () => React.useContext(AnalyticsContext);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  // <React.StrictMode>
  
  <AuthProvider>
    <AnalyticsContext.Provider value={{ track }}>
      <App />
    </AnalyticsContext.Provider>
  </AuthProvider>

  //</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
