import {
    createUserWithEmailAndPassword,
    getAuth,
    sendPasswordResetEmail,
    signInWithEmailAndPassword,
    User,
    updateEmail as firUpdateEmail,
    updatePassword as firUpdatePassword,
} from 'firebase/auth';

import React, { useEffect, useState, useContext } from 'react';

import { initializeApp } from 'firebase/app';

const firebaseConfig = {
  apiKey: "AIzaSyDy6P0hkGVpGsNyrnzeeu7NLooZc5Mo9nQ",
  authDomain: "chess-com-scout.firebaseapp.com",
  projectId: "chess-com-scout",
  storageBucket: "chess-com-scout.appspot.com",
  messagingSenderId: "389258368569",
  appId: "1:389258368569:web:a4073001cbc4ffa1409fe8",
  measurementId: "G-Z5G8LM131D"
};


export const firebaseApp = initializeApp(firebaseConfig);

export function auth() {
  return getAuth();
}

type AuthContextType = {
    currentUser?: User | null;
    isLoggedIn: boolean;
    login: (email: string, password: string) => Promise<any>;
    signup: (email: string, password: string) => Promise<any>;
    resetPassword: (email: string) => Promise<any>;
    signOut: () => void;
    updateEmail: (newEmail: string) => Promise<void>;
    updatePassword: (newPassword: string) => Promise<void>;
};

const AuthContext = React.createContext<AuthContextType>({
    currentUser: null,
    isLoggedIn: false,
    login: () => Promise.resolve(null),
    signup: () => Promise.resolve(null),
    resetPassword: () => Promise.resolve(null),
    signOut: () => {},
    updateEmail: () => Promise.resolve(),
    updatePassword: () => Promise.resolve(),
});

export function useAuth() {
  return useContext(AuthContext);
}

type AuthProviderProps = {
    children: React.ReactNode;
};

export default function AuthProvider({ children }: AuthProviderProps) {
  const [currentUser, setCurrentUser] = useState<User>();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const _auth = auth();
    const authObserver = _auth.onAuthStateChanged((user: User | null) => {
      if (user) {
        setCurrentUser(user);
        setIsLoading(false);

      } else {
        setCurrentUser(undefined);
        setIsLoading(false);
      }
    });
    
    return () => {
      authObserver();
    } 
  }, []);

  async function signup(email: string, password: string) {
    const _auth = auth();
    return createUserWithEmailAndPassword(_auth, email, password);
  }

  async function login(email: string, password: string) {
    const _auth = auth();
    return signInWithEmailAndPassword(_auth, email, password);
  }

  async function resetPassword(email: string) {
    const _auth = auth();
    return sendPasswordResetEmail(_auth, email);
  }

  async function updateEmail(newEmail: string) { 
    if (!currentUser) {
      throw new Error('Login needed to update email');
    }
    return firUpdateEmail(currentUser, newEmail);   
  }

  async function updatePassword(newPassword: string) {
    if (!currentUser) {
      throw new Error('Login needed to update password');
    }
    return firUpdatePassword(currentUser, newPassword);
  }

  function signOut() {
    const _auth = auth();
    _auth.signOut();
  }
  
  const value = {
    currentUser,

    isLoggedIn: !(!currentUser),
    login,
    signup,
    resetPassword,
    signOut,
    updateEmail,
    updatePassword,
  };
  return (<AuthContext.Provider value={value}>
    {!isLoading && children}
  </AuthContext.Provider>);
}