export enum ChessVariant {
    RAPID = 'chess_rapid',
    BLITZ = 'chess_blitz',
    BULLET = 'chess_bullet',
    DAILY = 'chess_daily',
    TACTICS = 'tactics',
}

export enum ChessRatingType {
    RAPID_SUB_500 = 'RAPID_SUB_500',
    RAPID_500s = 'RAPID_500s',
    RAPID_600s = 'RAPID_600s',
    RAPID_700s = 'RAPID_700s',
    RAPID_800s = 'RAPID_800s',
    RAPID_900s = 'RAPID_900s',
    RAPID_1000s = 'RAPID_1000s',
    RAPID_1100s = 'RAPID_1100s',
    RAPID_1200s = 'RAPID_1200s',
    RAPID_1300s = 'RAPID_1300s',
    RAPID_1400s = 'RAPID_1400s',
    RAPID_1500s = 'RAPID_1500s',
    RAPID_1600s = 'RAPID_1600s',
    RAPID_1700s = 'RAPID_1700s',
    RAPID_1800s = 'RAPID_1800s',
    RAPID_1900s = 'RAPID_1900s',
    RAPID_2000s = 'RAPID_2000s',
    RAPID_2100s = 'RAPID_2100s',
    RAPID_2200s = 'RAPID_2200s',
    RAPID_ABOVE_2299 = 'RAPID_ABOVE_2299',
}

export function ratingToRatingType(rating: number): ChessRatingType {
    if (rating < 500) {
        return ChessRatingType.RAPID_SUB_500;
    } else if (rating < 600) {
        return ChessRatingType.RAPID_500s;
    } else if (rating < 700) {
        return ChessRatingType.RAPID_600s;
    } else if (rating < 800) {
        return ChessRatingType.RAPID_700s;
    } else if (rating < 900) {
        return ChessRatingType.RAPID_800s;
    } else if (rating < 1000) {
        return ChessRatingType.RAPID_900s;
    } else if (rating < 1100) {
        return ChessRatingType.RAPID_1000s;
    } else if (rating < 1200) {
        return ChessRatingType.RAPID_1100s;
    } else if (rating < 1300) {
        return ChessRatingType.RAPID_1200s;
    } else if (rating < 1400) {
        return ChessRatingType.RAPID_1300s;
    } else if (rating < 1500) {
        return ChessRatingType.RAPID_1400s;
    } else if (rating < 1600) {
        return ChessRatingType.RAPID_1500s;
    } else if (rating < 1700) {
        return ChessRatingType.RAPID_1600s;
    } else if (rating < 1800) {
        return ChessRatingType.RAPID_1700s;
    } else if (rating < 1900) {
        return ChessRatingType.RAPID_1800s;
    } else if (rating < 2000) {
        return ChessRatingType.RAPID_1900s;
    } else if (rating < 2100) {
        return ChessRatingType.RAPID_2000s;
    } else if (rating < 2200) {
        return ChessRatingType.RAPID_2100s;
    } else if (rating < 2300) {
        return ChessRatingType.RAPID_2200s;
    } else {
        return ChessRatingType.RAPID_ABOVE_2299;
    }
}

export type Statistics = {
    
    mean: number;
    std: number;
    firstQuartile: number;
    secondQuartile: number;
    thirdQuartile: number;

};

export type RatingStatistics = { [key in ChessVariant]: Statistics };

export const ratingClassifications: { [key in ChessRatingType]: RatingStatistics } = {
    [ChessRatingType.RAPID_SUB_500]: {
        chess_rapid: {
            mean: 337.1924882629108,
            std: 115.69933195574535,
            firstQuartile: 239.0,
            secondQuartile: 353.0,
            thirdQuartile: 438.0,
        },
        chess_blitz: {
            mean: 327.2481203007519,
            std: 232.52528067232387,
            firstQuartile: 184.0,
            secondQuartile: 263.0,
            thirdQuartile: 401.0,
        },
        chess_bullet: {
            mean: 361.35483870967744,
            std: 253.35735396442342,
            firstQuartile: 172.0,
            secondQuartile: 300.0,
            thirdQuartile: 460.0,
        },
        chess_daily: {
            mean: 678.95,
            std: 287.3354754006696,
            firstQuartile: 400.0,
            secondQuartile: 668.5,
            thirdQuartile: 824.5,
        },
        tactics: {
            mean: 695.7793427230047,
            std: 387.7203246378784,
            firstQuartile: 400.0,
            secondQuartile: 486.0,
            thirdQuartile: 954.0,
        },
    },
    [ChessRatingType.RAPID_500s]: {
        chess_rapid: {
            mean: 550.5698924731183,
            std: 28.65893814951764,
            firstQuartile: 524.0,
            secondQuartile: 550.0,
            thirdQuartile: 576.0,
        },
        chess_blitz: {
            mean: 454.7536231884058,
            std: 208.72240259965764,
            firstQuartile: 304.0,
            secondQuartile: 437.0,
            thirdQuartile: 546.0,
        },
        chess_bullet: {
            mean: 420.95,
            std: 221.45623013229223,
            firstQuartile: 260.25,
            secondQuartile: 386.5,
            thirdQuartile: 497.5,
        },
        chess_daily: {
            mean: 663.7368421052631,
            std: 239.29013083301723,
            firstQuartile: 404.25,
            secondQuartile: 711.0,
            thirdQuartile: 837.0,
        },
        tactics: {
            mean: 914.505376344086,
            std: 500.0712476119498,
            firstQuartile: 431.0,
            secondQuartile: 800.0,
            thirdQuartile: 1327.0,
        },
    },
    [ChessRatingType.RAPID_600s]: {
        chess_rapid: {
            mean: 653.9444444444445,
            std: 29.50389847069009,
            firstQuartile: 627.0,
            secondQuartile: 655.5,
            thirdQuartile: 678.5,
        },
        chess_blitz: {
            mean: 583.9264705882352,
            std: 325.057850932767,
            firstQuartile: 395.75,
            secondQuartile: 469.0,
            thirdQuartile: 684.25,
        },
        chess_bullet: {
            mean: 565.0909090909091,
            std: 291.03291609224937,
            firstQuartile: 386.0,
            secondQuartile: 497.0,
            thirdQuartile: 687.5,
        },
        chess_daily: {
            mean: 790.969696969697,
            std: 347.3565787530593,
            firstQuartile: 559.0,
            secondQuartile: 741.0,
            thirdQuartile: 1009.0,
        },
        tactics: {
            mean: 983.0333333333333,
            std: 548.7007626806183,
            firstQuartile: 438.25,
            secondQuartile: 924.5,
            thirdQuartile: 1408.5,
        },
    },
    [ChessRatingType.RAPID_700s]: {
        chess_rapid: {
            mean: 750.953488372093,
            std: 28.217032883835284,
            firstQuartile: 727.0,
            secondQuartile: 751.0,
            thirdQuartile: 773.0,
        },
        chess_blitz: {
            mean: 599.6785714285714,
            std: 242.5003098088748,
            firstQuartile: 450.75,
            secondQuartile: 567.5,
            thirdQuartile: 709.0,
        },
        chess_bullet: {
            mean: 531.5657894736842,
            std: 287.99617291560503,
            firstQuartile: 357.0,
            secondQuartile: 473.0,
            thirdQuartile: 675.5,
        },
        chess_daily: {
            mean: 714.3389830508474,
            std: 300.3167069754914,
            firstQuartile: 513.0,
            secondQuartile: 693.0,
            thirdQuartile: 886.5,
        },
        tactics: {
            mean: 1180.937984496124,
            std: 577.0016891214713,
            firstQuartile: 621.0,
            secondQuartile: 1232.0,
            thirdQuartile: 1646.0,
        },
    },
    [ChessRatingType.RAPID_800s]: {
        chess_rapid: {
            mean: 851.9402985074627,
            std: 28.731780416905266,
            firstQuartile: 830.5,
            secondQuartile: 849.5,
            thirdQuartile: 876.5,
        },
        chess_blitz: {
            mean: 705.0084033613446,
            std: 266.7428297385342,
            firstQuartile: 498.0,
            secondQuartile: 645.0,
            thirdQuartile: 859.5,
        },
        chess_bullet: {
            mean: 631.3658536585366,
            std: 272.2541256677104,
            firstQuartile: 456.75,
            secondQuartile: 626.0,
            thirdQuartile: 780.0,
        },
        chess_daily: {
            mean: 867.1475409836065,
            std: 327.7232488989032,
            firstQuartile: 613.0,
            secondQuartile: 911.0,
            thirdQuartile: 1077.0,
        },
        tactics: {
            mean: 1243.5074626865671,
            std: 672.5742684346695,
            firstQuartile: 542.75,
            secondQuartile: 1216.0,
            thirdQuartile: 1800.0,
        },
    },
    [ChessRatingType.RAPID_900s]: {
        chess_rapid: {
            mean: 952.8275862068965,
            std: 29.42135109717332,
            firstQuartile: 926.0,
            secondQuartile: 953.0,
            thirdQuartile: 977.0,
        },
        chess_blitz: {
            mean: 806.0819672131148,
            std: 228.2054167501609,
            firstQuartile: 635.25,
            secondQuartile: 786.5,
            thirdQuartile: 951.75,
        },
        chess_bullet: {
            mean: 711.9625,
            std: 243.0728100405569,
            firstQuartile: 548.75,
            secondQuartile: 689.0,
            thirdQuartile: 847.5,
        },
        chess_daily: {
            mean: 839.6463414634146,
            std: 347.0573429671781,
            firstQuartile: 517.0,
            secondQuartile: 808.5,
            thirdQuartile: 1058.25,
        },
        tactics: {
            mean: 1380.1655172413793,
            std: 602.7078942133796,
            firstQuartile: 889.0,
            secondQuartile: 1411.0,
            thirdQuartile: 1945.0,
        },
    },
    [ChessRatingType.RAPID_1000s]: {
        chess_rapid: {
            mean: 1047.1337209302326,
            std: 29.93149336143523,
            firstQuartile: 1019.75,
            secondQuartile: 1046.5,
            thirdQuartile: 1072.5,
        },
        chess_blitz: {
            mean: 881.496855345912,
            std: 244.54113252452578,
            firstQuartile: 702.5,
            secondQuartile: 837.0,
            thirdQuartile: 1013.5,
        },
        chess_bullet: {
            mean: 827.0381679389313,
            std: 297.2275845098177,
            firstQuartile: 641.0,
            secondQuartile: 792.0,
            thirdQuartile: 976.5,
        },
        chess_daily: {
            mean: 918.8842105263158,
            std: 296.3501330247466,
            firstQuartile: 723.0,
            secondQuartile: 944.0,
            thirdQuartile: 1177.0,
        },
        tactics: {
            mean: 1486.0232558139535,
            std: 682.0322912000795,
            firstQuartile: 928.25,
            secondQuartile: 1520.5,
            thirdQuartile: 2014.25,
        },
    },
    [ChessRatingType.RAPID_1100s]: {
        chess_rapid: {
            mean: 1146.4825174825176,
            std: 29.39791435230631,
            firstQuartile: 1120.0,
            secondQuartile: 1147.0,
            thirdQuartile: 1169.0,
        },
        chess_blitz: {
            mean: 941.1221374045801,
            std: 262.0616786736331,
            firstQuartile: 765.0,
            secondQuartile: 923.0,
            thirdQuartile: 1034.5,
        },
        chess_bullet: {
            mean: 859.5670103092783,
            std: 315.6029912199983,
            firstQuartile: 657.0,
            secondQuartile: 824.0,
            thirdQuartile: 1014.0,
        },
        chess_daily: {
            mean: 1027.879518072289,
            std: 323.80980557285596,
            firstQuartile: 829.0,
            secondQuartile: 1067.0,
            thirdQuartile: 1243.5,
        },
        tactics: {
            mean: 1558.5384615384614,
            std: 683.1276226755914,
            firstQuartile: 1000.0,
            secondQuartile: 1542.0,
            thirdQuartile: 2138.0,
        },
    },
    [ChessRatingType.RAPID_1200s]: {
        chess_rapid: {
            mean: 1246.5642857142857,
            std: 31.120839143289956,
            firstQuartile: 1218.0,
            secondQuartile: 1242.0,
            thirdQuartile: 1271.25,
        },
        chess_blitz: {
            mean: 1107.248,
            std: 267.9430806190242,
            firstQuartile: 950.0,
            secondQuartile: 1068.0,
            thirdQuartile: 1248.0,
        },
        chess_bullet: {
            mean: 1060.049504950495,
            std: 350.77321380737226,
            firstQuartile: 830.0,
            secondQuartile: 1019.0,
            thirdQuartile: 1251.0,
        },
        chess_daily: {
            mean: 1014.4938271604939,
            std: 342.4928876435535,
            firstQuartile: 780.0,
            secondQuartile: 1086.0,
            thirdQuartile: 1249.0,
        },
        tactics: {
            mean: 1630.5642857142857,
            std: 755.0350338311698,
            firstQuartile: 1029.25,
            secondQuartile: 1720.5,
            thirdQuartile: 2210.0,
        },
    },
    [ChessRatingType.RAPID_1300s]: {
        chess_rapid: {
            mean: 1349.7758620689656,
            std: 31.873414356303847,
            firstQuartile: 1320.0,
            secondQuartile: 1353.0,
            thirdQuartile: 1379.0,
        },
        chess_blitz: {
            mean: 1263.5714285714287,
            std: 272.1257789340061,
            firstQuartile: 1077.0,
            secondQuartile: 1209.0,
            thirdQuartile: 1405.0,
        },
        chess_bullet: {
            mean: 1123.2692307692307,
            std: 307.5223008733928,
            firstQuartile: 934.75,
            secondQuartile: 1119.5,
            thirdQuartile: 1280.0,
        },
        chess_daily: {
            mean: 1132.1940298507463,
            std: 377.15880582627545,
            firstQuartile: 889.5,
            secondQuartile: 1200.0,
            thirdQuartile: 1395.0,
        },
        tactics: {
            mean: 1754.7241379310344,
            std: 784.1289000757675,
            firstQuartile: 1064.0,
            secondQuartile: 1980.5,
            thirdQuartile: 2391.25,
        },
    },
    [ChessRatingType.RAPID_1400s]: {
        chess_rapid: {
            mean: 1445.2833333333333,
            std: 28.53368445293008,
            firstQuartile: 1420.0,
            secondQuartile: 1443.5,
            thirdQuartile: 1469.25,
        },
        chess_blitz: {
            mean: 1311.3097345132744,
            std: 301.4555783328028,
            firstQuartile: 1087.0,
            secondQuartile: 1276.0,
            thirdQuartile: 1440.0,
        },
        chess_bullet: {
            mean: 1142.3111111111111,
            std: 340.89042835612,
            firstQuartile: 909.0,
            secondQuartile: 1114.5,
            thirdQuartile: 1321.75,
        },
        chess_daily: {
            mean: 1143.3648648648648,
            std: 356.13398180345973,
            firstQuartile: 966.0,
            secondQuartile: 1201.5,
            thirdQuartile: 1393.75,
        },
        tactics: {
            mean: 1947.7166666666667,
            std: 855.814057234528,
            firstQuartile: 1352.75,
            secondQuartile: 2196.0,
            thirdQuartile: 2486.25,
        },
    },
    [ChessRatingType.RAPID_1500s]: {
        chess_rapid: {
            mean: 1548.5913978494623,
            std: 30.855989679667292,
            firstQuartile: 1522.0,
            secondQuartile: 1546.0,
            thirdQuartile: 1576.0,
        },
        chess_blitz: {
            mean: 1374.909090909091,
            std: 213.3894845120384,
            firstQuartile: 1225.0,
            secondQuartile: 1366.5,
            thirdQuartile: 1493.75,
        },
        chess_bullet: {
            mean: 1218.8333333333333,
            std: 302.56567415932557,
            firstQuartile: 996.75,
            secondQuartile: 1221.5,
            thirdQuartile: 1423.25,
        },
        chess_daily: {
            mean: 1128.1,
            std: 373.8286331178706,
            firstQuartile: 938.75,
            secondQuartile: 1226.5,
            thirdQuartile: 1413.75,
        },
        tactics: {
            mean: 1989.978494623656,
            std: 718.3167216318787,
            firstQuartile: 1437.0,
            secondQuartile: 2255.0,
            thirdQuartile: 2511.0,
        },
    },
    [ChessRatingType.RAPID_1600s]: {
        chess_rapid: {
            mean: 1644.6666666666667,
            std: 30.87705846213216,
            firstQuartile: 1617.25,
            secondQuartile: 1644.5,
            thirdQuartile: 1672.0,
        },
        chess_blitz: {
            mean: 1509.6363636363637,
            std: 220.19770590222123,
            firstQuartile: 1371.5,
            secondQuartile: 1492.0,
            thirdQuartile: 1598.5,
        },
        chess_bullet: {
            mean: 1360.9113924050632,
            std: 309.0592089101034,
            firstQuartile: 1204.0,
            secondQuartile: 1316.0,
            thirdQuartile: 1501.0,
        },
        chess_daily: {
            mean: 1307.4912280701753,
            std: 386.0452010362359,
            firstQuartile: 1092.0,
            secondQuartile: 1284.0,
            thirdQuartile: 1589.0,
        },
        tactics: {
            mean: 2127.9117647058824,
            std: 766.0430164348071,
            firstQuartile: 1648.0,
            secondQuartile: 2384.5,
            thirdQuartile: 2680.5,
        },
    },
    [ChessRatingType.RAPID_1700s]: {
        chess_rapid: {
            mean: 1748.0694444444443,
            std: 27.660581801590943,
            firstQuartile: 1726.75,
            secondQuartile: 1747.0,
            thirdQuartile: 1768.5,
        },
        chess_blitz: {
            mean: 1589.7464788732395,
            std: 241.52436838368678,
            firstQuartile: 1462.5,
            secondQuartile: 1553.0,
            thirdQuartile: 1693.5,
        },
        chess_bullet: {
            mean: 1411.132075471698,
            std: 352.0690512328429,
            firstQuartile: 1145.0,
            secondQuartile: 1415.0,
            thirdQuartile: 1613.0,
        },
        chess_daily: {
            mean: 1295.4347826086957,
            std: 402.51573618232135,
            firstQuartile: 1050.5,
            secondQuartile: 1358.0,
            thirdQuartile: 1545.25,
        },
        tactics: {
            mean: 2077.222222222222,
            std: 931.8102512249744,
            firstQuartile: 1290.0,
            secondQuartile: 2481.5,
            thirdQuartile: 2776.25,
        },
    },
    [ChessRatingType.RAPID_1800s]: {
        chess_rapid: {
            mean: 1843.5,
            std: 30.92754145600359,
            firstQuartile: 1816.5,
            secondQuartile: 1835.0,
            thirdQuartile: 1873.25,
        },
        chess_blitz: {
            mean: 1704.2285714285715,
            std: 288.7221426047058,
            firstQuartile: 1536.0,
            secondQuartile: 1705.0,
            thirdQuartile: 1824.5,
        },
        chess_bullet: {
            mean: 1666.4,
            std: 297.56749934538664,
            firstQuartile: 1478.0,
            secondQuartile: 1608.0,
            thirdQuartile: 1825.0,
        },
        chess_daily: {
            mean: 1335.3,
            std: 452.2037384328347,
            firstQuartile: 1047.5,
            secondQuartile: 1354.0,
            thirdQuartile: 1746.0,
        },
        tactics: {
            mean: 2022.5,
            std: 864.5611603582479,
            firstQuartile: 1206.75,
            secondQuartile: 2247.5,
            thirdQuartile: 2769.0,
        },
    },
    [ChessRatingType.RAPID_1900s]: {
        chess_rapid: {
            mean: 1943.2325581395348,
            std: 27.023655606666726,
            firstQuartile: 1927.0,
            secondQuartile: 1945.0,
            thirdQuartile: 1962.5,
        },
        chess_blitz: {
            mean: 1849.953488372093,
            std: 309.9784903050972,
            firstQuartile: 1673.0,
            secondQuartile: 1771.0,
            thirdQuartile: 2001.0,
        },
        chess_bullet: {
            mean: 1694.8918918918919,
            std: 412.17713181105523,
            firstQuartile: 1421.0,
            secondQuartile: 1661.0,
            thirdQuartile: 1937.0,
        },
        chess_daily: {
            mean: 1408.2903225806451,
            std: 481.3502670300417,
            firstQuartile: 1260.0,
            secondQuartile: 1572.0,
            thirdQuartile: 1679.0,
        },
        tactics: {
            mean: 2363.0,
            std: 994.2854816639027,
            firstQuartile: 1698.5,
            secondQuartile: 2728.0,
            thirdQuartile: 3060.0,
        },
    },
    [ChessRatingType.RAPID_2000s]: {
        chess_rapid: {
            mean: 2047.2058823529412,
            std: 30.089542542384756,
            firstQuartile: 2020.5,
            secondQuartile: 2044.5,
            thirdQuartile: 2070.75,
        },
        chess_blitz: {
            mean: 1922.6176470588234,
            std: 304.4594827704359,
            firstQuartile: 1769.5,
            secondQuartile: 1944.0,
            thirdQuartile: 2044.25,
        },
        chess_bullet: {
            mean: 1782.967741935484,
            std: 361.97877321476255,
            firstQuartile: 1575.5,
            secondQuartile: 1739.0,
            thirdQuartile: 1978.0,
        },
        chess_daily: {
            mean: 1463.55,
            std: 446.8891861936716,
            firstQuartile: 1278.75,
            secondQuartile: 1490.0,
            thirdQuartile: 1762.0,
        },
        tactics: {
            mean: 2432.9117647058824,
            std: 1091.731606700804,
            firstQuartile: 1632.5,
            secondQuartile: 2769.5,
            thirdQuartile: 3119.25,
        },
    },
    [ChessRatingType.RAPID_2100s]: {
        chess_rapid: {
            mean: 2150.3333333333335,
            std: 29.968237153613796,
            firstQuartile: 2131.5,
            secondQuartile: 2143.0,
            thirdQuartile: 2176.5,
        },
        chess_blitz: {
            mean: 2176.0,
            std: 187.90218404593057,
            firstQuartile: 2007.5,
            secondQuartile: 2123.5,
            thirdQuartile: 2278.5,
        },
        chess_bullet: {
            mean: 2058.5454545454545,
            std: 380.52736133854125,
            firstQuartile: 1813.0,
            secondQuartile: 2092.0,
            thirdQuartile: 2373.5,
        },
        chess_daily: {
            mean: 1635.6,
            std: 459.309590581342,
            firstQuartile: 1450.0,
            secondQuartile: 1472.0,
            thirdQuartile: 2053.0,
        },
        tactics: {
            mean: 2399.866666666667,
            std: 1152.8614627876814,
            firstQuartile: 1439.0,
            secondQuartile: 3072.0,
            thirdQuartile: 3267.5,
        },
    },
    [ChessRatingType.RAPID_2200s]: {
        chess_rapid: {
            mean: 2237.75,
            std: 22.547424767284703,
            firstQuartile: 2220.25,
            secondQuartile: 2247.0,
            thirdQuartile: 2254.5,
        },
        chess_blitz: {
            mean: 2276.75,
            std: 176.45299399814414,
            firstQuartile: 2162.0,
            secondQuartile: 2275.0,
            thirdQuartile: 2358.0,
        },
        chess_bullet: {
            mean: 2103.090909090909,
            std: 425.34279223832027,
            firstQuartile: 1871.0,
            secondQuartile: 2213.0,
            thirdQuartile: 2386.0,
        },
        chess_daily: {
            mean: 1505.4285714285713,
            std: 354.885360430124,
            firstQuartile: 1337.5,
            secondQuartile: 1450.0,
            thirdQuartile: 1676.0,
        },
        tactics: {
            mean: 2828.0833333333335,
            std: 944.1467969772625,
            firstQuartile: 2727.75,
            secondQuartile: 3298.5,
            thirdQuartile: 3368.25,
        },
    },
    [ChessRatingType.RAPID_ABOVE_2299]: {
        chess_rapid: {
            mean: 2237.75,
            std: 22.547424767284703,
            firstQuartile: 2220.25,
            secondQuartile: 2247.0,
            thirdQuartile: 2254.5,
        },
        chess_blitz: {
            mean: 2276.75,
            std: 176.45299399814414,
            firstQuartile: 2162.0,
            secondQuartile: 2275.0,
            thirdQuartile: 2358.0,
        },
        chess_bullet: {
            mean: 2103.090909090909,
            std: 425.34279223832027,
            firstQuartile: 1871.0,
            secondQuartile: 2213.0,
            thirdQuartile: 2386.0,
        },
        chess_daily: {
            mean: 1505.4285714285713,
            std: 354.885360430124,
            firstQuartile: 1337.5,
            secondQuartile: 1450.0,
            thirdQuartile: 1676.0,
        },
        tactics: {
            mean: 2828.0833333333335,
            std: 944.1467969772625,
            firstQuartile: 2727.75,
            secondQuartile: 3298.5,
            thirdQuartile: 3368.25,
        },
    },
};

export type ChessComRating = {
    rating: number;
    date: number;
    rd: number;
};

export type ChessComGameRecord = {
    win: number;
    loss: number;
    draw: number;
    time_per_move: number;
    timeout_percent: number;
};

export type ChessComGame = {
    rating: number;
    date: number;
    game: string;
};

export type ChessComRatingStats = {
    last: Partial<ChessComRating>;
    best: Partial<ChessComRating>;
    record: Partial<ChessComGameRecord>;
}

export type ChessComTactics = {
    highest: ChessComRating;
    lowest: ChessComRating;
};

export type ChessComPuzzleRush = {
    best: {
        total_attempts: number;
        score: number;
    };
    daily: {
        total_attempts: number;
        score: number;
    };
};

export type ChessComProfileStats = {
    chess_daily: ChessComRatingStats;
    chess960_daily: ChessComRatingStats;
    chess_rapid: ChessComRatingStats;
    chess_bullet: ChessComRatingStats;
    chess_blitz: ChessComRatingStats;
    fide: number;
    tactics: ChessComTactics;
    puzzle_rush: ChessComPuzzleRush;
};

export enum TacticsSharpness {
    VERY_LOW_FOR_RATING = 'VERY_LOW_FOR_RATING',
    LOW_FOR_RATING = 'LOW_FOR_RATING',
    MEDIUM = 'MEDIUM',
    HIGH_FOR_RATING = 'HIGH_FOR_RATING',
    VERY_HIGH_FOR_RATING = 'VERY_HIGH_FOR_RATING',
    EXCEPTIONAL = 'EXCEPTIONAL',
}

export enum TimePressurePerformance {
    LOW = 'LOW',
    MEDIUM = 'MEDIUM',
    HIGH = 'HIGH',
}

export type ProfileStatFacts = {
    ratingType: ChessRatingType;
    dailyRating: number;
    rapidRating: number;
    blitzRating: number;
    bulletRating: number;
    tacticsRating: number;
    tacticsAbility: TacticsSharpness;
    performanceUnderTimePressure: TimePressurePerformance;
}

export async function getProfileStats(username: string): Promise<Partial<ChessComProfileStats>> {
    const response = await fetch(`https://api.chess.com/pub/player/${username}/stats`);
    return (await response.json()) as Partial<ChessComProfileStats>;
}

export function getProfileStatFacts(stats: Partial<ChessComProfileStats>): ProfileStatFacts {
    // tacticsAbility is considered "sharp" if the user's tactics rating is above the 50th percentile in ratingClassifications for their rapid rating range

    const dailyRating = stats.chess_daily?.last?.rating ?? 0
    const rapidRating = stats.chess_rapid?.last?.rating ?? 0
    const blitzRating = stats.chess_blitz?.last?.rating ?? 0
    const bulletRating = stats.chess_bullet?.last?.rating ?? 0
    const tacticsRating = stats.tactics?.highest?.rating ?? 0;

    const ratingType = ratingToRatingType(rapidRating);

    let tacticsSharpness: TacticsSharpness;

    if (tacticsRating > 5000) {
        tacticsSharpness = TacticsSharpness.EXCEPTIONAL;
    } else if (tacticsRating >= ratingClassifications[ratingType].tactics.thirdQuartile) {
        tacticsSharpness = TacticsSharpness.VERY_HIGH_FOR_RATING;
    } else if (tacticsRating >= ratingClassifications[ratingType].tactics.secondQuartile) {
        tacticsSharpness = TacticsSharpness.HIGH_FOR_RATING;
    } else if (tacticsRating ?? 0 >= ratingClassifications[ratingType].tactics.firstQuartile) {
        tacticsSharpness = TacticsSharpness.MEDIUM;
    } else if (tacticsRating ?? 0 < ratingClassifications[ratingType].tactics.firstQuartile) {
        tacticsSharpness = TacticsSharpness.LOW_FOR_RATING;
    
        if (tacticsRating < ratingClassifications[ratingType].tactics.firstQuartile - 100) {
            tacticsSharpness = TacticsSharpness.VERY_LOW_FOR_RATING;
        }
    } else {
        tacticsSharpness = TacticsSharpness.VERY_LOW_FOR_RATING;
    }

    let performanceUnderTimePressure: TimePressurePerformance;

    if (bulletRating > ratingClassifications[ratingType].chess_bullet.secondQuartile || blitzRating > ratingClassifications[ratingType].chess_blitz.secondQuartile) {
        performanceUnderTimePressure = TimePressurePerformance.HIGH;
    } else if (bulletRating >= ratingClassifications[ratingType].chess_bullet.firstQuartile || blitzRating >= ratingClassifications[ratingType].chess_blitz.firstQuartile) {
        performanceUnderTimePressure = TimePressurePerformance.MEDIUM;
    } else {
        performanceUnderTimePressure = TimePressurePerformance.LOW;
    }

    const ratingHigherThanClass = rapidRating >= 2400 || blitzRating >= 2400 || dailyRating >= 2400;
    // @ts-ignore
    const highTacticsSharpness = tacticsSharpness && (tacticsSharpness === TacticsSharpness.HIGH_FOR_RATING || tacticsSharpness === TacticsSharpness.HIGH_FOR_RATING || tacticsSharpness === TacticsSharpness.VERY_HIGH_FOR_RATING || tacticsSharpness === TacticsSharpness.EXCEPTIONAL);
    const overwrittenTacticsAbility = (!highTacticsSharpness && ratingHigherThanClass) ? TacticsSharpness.HIGH_FOR_RATING : tacticsSharpness;

    return {
        ratingType,
        dailyRating,
        rapidRating,
        blitzRating,
        bulletRating,
        tacticsRating,
        tacticsAbility: overwrittenTacticsAbility,
        performanceUnderTimePressure,
    };
}

function zeroPad(num: number, places: number) {
    return String(num).padStart(places, '0');
}

async function getGames(username: string, year: number, month: number): Promise<any[]> {
    const gameResponse = await fetch(`https://api.chess.com/pub/player/${username}/games/${year}/${zeroPad(month, 2)}`);
        
    if (!gameResponse.ok) {
        throw new Error('Failed to fetch games');
    }

    const gameJSON = await gameResponse.json();
    
    const supportedTimeClasses = ['daily', 'rapid', 'blitz'];
    
    return gameJSON.games.filter((g: any) => {
        const whiteRating = g.white?.rating ?? 1500;
        const blackRating = g.black?.rating ?? 1500;
        const isWhite = g.white.username.toLowerCase() === username.toLowerCase();
        const ratingDifference = isWhite ? whiteRating - blackRating : blackRating - whiteRating;
        const bothRatingsHigherThan2000 = whiteRating > 2000 && blackRating > 2000;
        const ratingIsOK = ratingDifference < 300 || (bothRatingsHigherThan2000 && ratingDifference < 800);
        
        // game did not start from a setup position
        const gameDidNotStart = g.fen.startsWith('rnbqkbnr/pppppppp/8/8/8/8/PPPPPPPP/RNBQKBNR w KQkq');

        return !gameDidNotStart && ratingIsOK && supportedTimeClasses.includes(g.time_class) && !g.pgn.includes('[SetUp');
    });
}


// Try to get at least 50 games but no more than the latest 100 games from the last 5 months
export async function getLatestGames(username: string): Promise<{ games: any[], facts: ProfileStatFacts }> {
    const stats = await getProfileStats(username);

    const lastRapidDate = stats.chess_rapid?.last?.date;
    const lastBlitzDate = stats.chess_blitz?.last?.date;
    const lastDailyDate = stats.chess_daily?.last?.date;
    
    if (!lastRapidDate && !lastBlitzDate && !lastDailyDate) {
        throw new Error(`Not enough recent games found for "${username}"`);
    }

    const lastRapid = lastRapidDate ? new Date(lastRapidDate * 1000) : undefined;
    const lastBlitz = lastBlitzDate ? new Date(lastBlitzDate * 1000) : undefined;
    const lastDaily = lastDailyDate ? new Date(lastDailyDate * 1000) : undefined;

    const latestNonNullDate = [lastRapid, lastBlitz, lastDaily].filter(date => date !== undefined).sort((a, b) => {
        return b!.getTime() - a!.getTime();
    })[0]!;

    // if more than a year ago fail
    if (latestNonNullDate.getTime() < Date.now() - 365 * 24 * 60 * 60 * 1000) {
        throw new Error(`Not enough recent games found for "${username}"`);
    }

    const now = latestNonNullDate;
    const year = now.getFullYear();
    const month = now.getMonth() + 1;
    let totalGames: any[] = [];
    for (let i = 0; i < 3; i++) {
        const games = await getGames(username, year, month - i);
        totalGames.push(...games);
        if (totalGames.length >= 100) {
            break;
        }
    }
    if (totalGames.length < 50) {
        throw new Error(`Not enough recent games found for "${username}"`);
    }
    // return first 100 values of totalGames
    return {
      games: totalGames.slice(0, 100),
      facts: getProfileStatFacts(stats),
    };
}