
import React from 'react';
import './SearchBarStyles.css';
import Loader from '../Loader/Loader';
import { getLatestGames } from '../../apiUtils';

/*{
    "avatar": "https://images.chesscomfiles.com/uploads/v1/user/107782742.3849c654.200x200o.96bd7774cfa2.jpeg",
    "username" :"thorvaldurru",
    "country": "https://api.chess.com/pub/country/IS"
}*/
// {"code":0,"message":"User \"bulslslslslslslslsls\" not found."}

interface UserOptionResponse {
    avatar?: string;
    username?: string;
    country?: string;
    code?: number;
    message?: string;
}

function throttle(callback: (search: string) => void, wait: number) {
    let timeout: NodeJS.Timeout;
    return (searchTerm: string) => {
        clearTimeout(timeout);
        timeout = setTimeout(() => {
            callback(searchTerm);
        }, wait);
    };
}

type SearchBarProps = {
    handleSelectedUserData: (userData?: { chessComUsername: string; avatar: string; games: any[]; facts: any; }) => void;
    label: string;
    id: string;
    
};

function SearchBar({handleSelectedUserData, }: SearchBarProps) {
    const [isLoading, setIsLoading] = React.useState(false);
    const [userOptionResponse, setUserOptionResponse] = React.useState<UserOptionResponse>({});

    const [selectedVal, setSelectedVal] = React.useState<string | null>(null);

    const [query, setQuery] = React.useState("");
    
    const inputRef = React.useRef<HTMLInputElement>(null);

    const throttledAPICall = React.useCallback(throttle((searchTerm: string) => {
        // make API call with searchTerm
        // console.log('SEARCHING: ', searchTerm);
        setIsLoading(true);
        // fetch(`https://www.chess.com/callback/friends/vladirunner/get-friends?avatarSize=128&orderByFriendsFirst=1&name=${searchTerm}`, requestInfo)
        return fetch(`https://api.chess.com/pub/player/${searchTerm}`)
            .then(response => {
                return response.json();
            }).then(data => {
                if (data.message?.includes('not found')) {
                    setUserOptionResponse({
                        code: 0,
                        message: `User "${searchTerm}" not found on Chess.com`
                    });    
                } else {
                    setUserOptionResponse(data as UserOptionResponse);
                }
                setIsLoading(false);
            }).catch(() => {
                setIsLoading(false);
            });
    }, 500), [setIsLoading]);

    React.useEffect(() => {
        setUserOptionResponse({});
        handleSelectedUserData();
        if (!query) {
            return;
        }

        throttledAPICall(query);
    }, [query, setUserOptionResponse, handleSelectedUserData, throttledAPICall]);

    const getDisplayValue = () => {
      if (query) {
        return query;
      }
      if (selectedVal) {
        return selectedVal;
      }

      return "";
    };

    const onOptionClick = React.useCallback(({ username, avatar }: { username?: string; avatar?: string; }) => {
        return () => {
            setIsLoading(true);
            getLatestGames(username ?? '').then(({ games, facts }) => {
                setIsLoading(false);
                setSelectedVal(userOptionResponse.username ?? '');
                handleSelectedUserData({
                    chessComUsername: userOptionResponse.username ?? '',
                    avatar: avatar ?? '',
                    games: games,
                    facts: facts,
                });
            }).catch(error => {
                setUserOptionResponse({code: 0, message: error.message});
                setIsLoading(false);
                handleSelectedUserData();
            })
        }
    }, [userOptionResponse.username, handleSelectedUserData, setIsLoading]);

    return (
        
    <div className="dropdown">
      <div className="control">
        <div className="selected-value">
          <input
            ref={inputRef}
            type="text"
            value={getDisplayValue()}
            name="searchTerm"
            placeholder='Chess.com username'
            onChange={(e) => {
              setQuery(e.target.value);
              setSelectedVal(null);
            }}
          />
        </div>
        {userOptionResponse.message && (<p className='error'>
            {userOptionResponse.message}
        </p>)}
      </div>
      {isLoading ? <Loader /> :
        <div className={`options open ${
            userOptionResponse.username === selectedVal ? "selected" : ""
        }`}>
            {userOptionResponse.username &&
            (
                <div
                className='option'
                key={userOptionResponse.username}
                onClick={userOptionResponse.username !== selectedVal ? onOptionClick(userOptionResponse) : undefined}>
                {userOptionResponse.avatar ? <img className='icon' src={userOptionResponse.avatar} alt="avatar"/> : <div className='icon' /> }
                <p>

                    {userOptionResponse.username}
                </p>
                </div>
            )}
            </div>
      }
    </div>
    );
}

export default SearchBar;