
import React, { FormEventHandler, lazy, Suspense } from 'react';

import logo from '../../logo.svg';
import './LoggedInHeaderStyles.css';

import { useAuth } from '../../context/AuthContext';

import SearchBar from '../SearchBar/SearchBar';
import { ProfileStatFacts } from '../../apiUtils';
import Dialog from '../Dialog/Dialog';
import { GameReport } from '../../mocks/games';
import Loader from '../Loader/Loader';

const LazyScoutReportComponent = lazy(() => import('../ScoutReport/ScoutReport'));

type LoggedInHeaderProps = {
    checkPayment: () => Promise<boolean> | undefined;
    showPaymentDialog: () => void;
};


function LoggedInHeader({ checkPayment, showPaymentDialog }: LoggedInHeaderProps) {
    const { currentUser } = useAuth();

    const [isScoutReportModalOpen, setIsScoutReportModalOpen] = React.useState(false);
    const [isLoadingScoutReport, setIsLoadingScoutReport] = React.useState(false);
    const [scoutReportError, setScoutReportError] = React.useState<string>();
    const [gameReports, setGameReports] = React.useState<GameReport[]>();

    const [selectedUserData, setSelectedUserData] = React.useState<{
        chessComUsername: string;
        avatar: string;
        games: any[];
        facts: ProfileStatFacts;
    }>();
    
    React.useEffect(() => {
        if ((gameReports ?? []).length > 0) {
            setIsScoutReportModalOpen(true);
        }
    }, [gameReports, setIsScoutReportModalOpen]);

    const handleCloseScoutReport = React.useCallback((result: boolean) => {
        setIsScoutReportModalOpen(false);
        setGameReports(undefined);
    }, [setIsScoutReportModalOpen]);

    const handleSubmit: FormEventHandler<HTMLFormElement> = React.useCallback((event) => {
        // Prevent default form submission
        event.preventDefault();

        // take focus away from the input and button
        event.currentTarget.blur();
    
        const userData = {
            games: selectedUserData?.games,
            chessComUsername: selectedUserData?.chessComUsername,
        };

        setIsLoadingScoutReport(true);

        checkPayment()?.then((isPaymentValid) => {
            if (!isPaymentValid) {
                setIsLoadingScoutReport(false);
                showPaymentDialog();
                return;
            }

            currentUser?.getIdToken(true).then((idToken) => {
                fetch('/api/reports', {
                  method: "POST",
                  headers: {
                    "Authorization": `Bearer ${idToken}`,
                    "Content-Type": "application/json",
                  },
                  // use the "body" param to optionally pass additional order information
                  // like product ids and quantities
                  body: JSON.stringify({
                    selectedUserData: userData,
                  }),
                }).then(response => {
                  return response.json();
                }).then((data) => {
                    setScoutReportError(undefined);
                    setGameReports(data.gameReports);
                    setIsLoadingScoutReport(false);
                })
                .catch((error) => {
                  console.error('error fetching reports', error);
                  setScoutReportError('Error fetching reports');
                  setIsLoadingScoutReport(false);
                });
            });
        }).catch(() => {
            showPaymentDialog();
        })
    }, [selectedUserData, setGameReports, currentUser, checkPayment, showPaymentDialog]);
    
    const handleSelectedUserData = React.useCallback((userData?: { chessComUsername: string; avatar: string; games: any[]; facts: ProfileStatFacts; }) => {
        setSelectedUserData(userData);
    }, [setSelectedUserData]);

    return (
        <div className='container loggedInHeader'>
            <header className="App-header">
                <div className="row">
                    <img src={logo} className="App-logo" alt="logo" />
                    <h1 className='frontpageTitle'>AI Powered Chess Preparation</h1>
                </div>
                
                <div className='row u-full-width'>
                    <div className="u-full-width">
                        <form className='container u-full-width' onSubmit={handleSubmit}>
                            <div className="row u-full-width">
                                <SearchBar
                                    handleSelectedUserData={handleSelectedUserData} 
                                    label="name"
                                    id="id"
                                />
                            </div>
                            <div className='row u-full-width'>
                                <div className="u-full-width">
                                    {(selectedUserData?.chessComUsername && !isLoadingScoutReport) && <input data-open-modal className="button-primary" type="submit" value="Scout Player" />}
                                </div>
                            </div>
                            {isLoadingScoutReport && <Loader size='medium' color='white' />}
                            {scoutReportError && <p className='error'>{scoutReportError}</p>}
                        </form>
                    </div>
                </div>
            </header>
            <Dialog showDownloadButton isDialogModalOpen={isScoutReportModalOpen} title='Login' close={handleCloseScoutReport}>
                {selectedUserData && (
                    <Suspense fallback={<>
                        <Loader color='black' size='large' />
                    </>}>
                        <LazyScoutReportComponent
                            selectedUserData={selectedUserData}
                            reports={gameReports ?? []}
                            handleCloseDialog={handleCloseScoutReport}
                        />
                    </Suspense>
                )}
            </Dialog>
        </div>
    );
}

export default LoggedInHeader;