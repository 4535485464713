import React from 'react';
import './AuthDialogStyles.css';
import {
    getAuth,
    GoogleAuthProvider,
} from 'firebase/auth';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';

import Dialog, { DialogProps } from '../Dialog/Dialog';
import firebaseui from 'firebaseui';
import { sendSignInLink, validateEmail } from '../../utils/authUtils';
import Loader from '../Loader/Loader';

const firebaseUIConfig: firebaseui.auth.Config = {
    signInFlow: 'popup',
    signInOptions: [
        GoogleAuthProvider.PROVIDER_ID,
    ],
    signInSuccessUrl: '/',
};

const AuthDialog = (props: Partial<DialogProps>) => {
  const [isLoading, setIsLoading] = React.useState(false);
  const inputRef = React.useRef<HTMLInputElement>(null);
  const [email, setEmail] = React.useState('');
  const [errorMessage, setErrorMessage] = React.useState<string>();
  const [successMessage, setSuccessMessage] = React.useState<string>();

  const handleClose = React.useCallback((result: boolean) => {
    setIsLoading(false);
    setErrorMessage(undefined);
    setSuccessMessage(undefined);
    props.close && props.close(result);
  }, [props]);

  const handleContinueClicked = React.useCallback(() => {
    if (!email || !validateEmail(email)) {
      setErrorMessage('Please enter a valid email address');
      return;
    }
    setErrorMessage(undefined);
    setIsLoading(true);
    
    sendSignInLink(email).then(() => {
        setIsLoading(false);
        setSuccessMessage('Sign in link sent to your email. Please check your inbox.');
    }).catch((error) => {
        console.error(error);
        setErrorMessage('Error logging in');
    });;
    
  }, [email, setErrorMessage]);

  return (
    <Dialog isDialogModalOpen={props.isDialogModalOpen ?? false} title={props.title ?? ' '} ribbonText={props.ribbonText} showDownloadButton={props.showDownloadButton ?? false} close={handleClose}>
        <div className='auth-dialog-content'>
            <StyledFirebaseAuth uiConfig={firebaseUIConfig} firebaseAuth={getAuth()} />
            <p>or</p>
            <div className='loginWithEmailContainer u-full-width'>

                <input
                  className='u-full-width'
                  ref={inputRef}
                  type="text"
                  value={email}
                  name="searchTerm"
                  placeholder='Continue with email'
                  onChange={(e) => {
                    setErrorMessage(undefined);
                    setEmail(e.target.value);
                  }}
                />
                {errorMessage && <p className='error'>{errorMessage}</p>}
                {successMessage && <p className='success'>{successMessage}</p>}
                {(!isLoading && !successMessage) && <input type='button' className="button-primary u-full-width" onClick={handleContinueClicked} value='Continue' />}
                
                {isLoading && <Loader size='small' color='black' />}
            </div>
        </div>
    </Dialog>
  );
};

export default AuthDialog;